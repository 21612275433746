import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SubNavList from "./SubNavList";

const useStyle = makeStyles((theme) => ({
  navbarDrawer: {
    display: "flex",
  },
  navbarDrawerUL: {
    listStyle: "none",
    justifyContent: "center",
    [theme.breakpoints.only("md")]: {
      margin: 0,
      paddingInlineStart: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      textAlign: "center",
      margin: 60,
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      textAlign: "center",
      margin: 10,
      padding: 0,
    },
  },

  navMenu: {
    padding: "10px",
    color: theme.palette.common.black,
    margin: "1em",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  NavBtn: {
    cursor: "pointer",
    transition: "color ease-in-out 300ms",
    fontFamily: "GothicRegular",
    display: "inline-block",
    fontSize: ".75rem",
    [theme.breakpoints.only("md")]: {
      fontSize: ".65rem",
    },
    "&:hover": {
      color: `black !important`,
    },
    "& ul": {
      padding: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px", // 7px
      fontSize: ".8rem",
    },
  },
  hovered: {
    color: theme.palette.grey.light,
  },
  unClicked: {
    color: theme.palette.grey.light,
  },
  ConditionClicked: {
    color: theme.palette.common.black,
    //color:theme.palette.primary.main
  },
  TreatmentClicked: {
    color: theme.palette.common.black,
    //color:theme.palette.primary.main
  },
  drbtntClicked: {
    color: theme.palette.common.black,
  },
  StoryClicked: {
    color: theme.palette.common.black,
  },
  AfterClicked: {
    color: theme.palette.common.black,
  },
  giftVocuherLi: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const NavList = ({
  openSubNavListfunc,
  isNavDrawerOpen,
  setclickCondition,
  setclickTreatment,
  closeMenuFunc,
  history,
  topOpen,
  width,
}) => {
  const classes = useStyle();

  useEffect(() => {
    setclickCondition(false);
    setclickTreatment(false);
    setclickMeetDr(false);
  }, []);

  useEffect(() => {
    setclickCondition(false);
    setclickTreatment(false);
    setclickMeetDr(false);
  }, []);

  useEffect(() => {
    if (!isNavDrawerOpen) {
      setclickCondition(false);
      setclickTreatment(false);
    }
  }, [isNavDrawerOpen]);

  const [clickMeetDr, setclickMeetDr] = useState(false);

  const [hover, sethover] = useState(false);

  function HoverEffect() {
    sethover(true);
  }

  function HoveroutEffect() {
    sethover(false);
  }

  return (
    <div className={classes.navbarDrawer}>
      <ul className={classes.navbarDrawerUL}>
        <li
          onClick={(e) => openSubNavListfunc("condition", e)}
          onMouseEnter={() => HoverEffect()}
          onMouseLeave={() => HoveroutEffect()}
          className={clsx(classes.NavBtn, {
            [classes.hovered]: hover,
          })}
        >
          CONDITIONS <span className={classes.navMenu}>|</span>
        </li>
        {topOpen === "condition" && width < 860 && (
          <div>
            <SubNavList
              closeMenuFunc={closeMenuFunc}
              topOpen={topOpen}
              isNavDrawerOpen={isNavDrawerOpen}
            />
          </div>
        )}

        <li
          onMouseEnter={() => HoverEffect()}
          onMouseLeave={() => HoveroutEffect()}
          onClick={(e) => openSubNavListfunc("treatment", e)}
          className={clsx(classes.NavBtn, {
            [classes.hovered]: hover,
          })}
        >
          TREATMENTS <span className={classes.navMenu}>|</span>
        </li>
        {topOpen === "treatment" && width < 860 && (
          <SubNavList
            closeMenuFunc={closeMenuFunc}
            topOpen={topOpen}
            isNavDrawerOpen={isNavDrawerOpen}
          />
        )}
        <li
          onMouseEnter={() => HoverEffect()}
          onMouseLeave={() => HoveroutEffect()}
          onClick={() => {
            closeMenuFunc();
            history.push("/meet-dr-palin");
          }}
          className={clsx(classes.NavBtn, {
            [classes.hovered]: hover,
            [classes.drbtntClicked]: clickMeetDr,
          })}
        >
          MEET DR PALIN <span className={classes.navMenu}>|</span>
        </li>
        <li
          onMouseEnter={() => HoverEffect()}
          onMouseLeave={() => HoveroutEffect()}
          onClick={() => {
            closeMenuFunc();
            history.push("/after-care");
          }}
          className={clsx(classes.NavBtn, {
            [classes.hovered]: hover,
          })}
        >
          AFTERCARE
          <span className={classes.navMenu}>|</span>
        </li>
        <li
          onMouseEnter={() => HoverEffect()}
          onMouseLeave={() => HoveroutEffect()}
          onClick={() => {
            closeMenuFunc();
            // history.push("/gift");
            window.location.href =
              "https://partner.pabau.com/online-bookings/DPA-Clinics?prepaidType=voucher&prepaidFlow=true&medicalForms=true";
          }}
          className={clsx(classes.NavBtn, classes.giftVocuherLi, {
            [classes.hovered]: hover,
          })}
        >
          GIFT VOUCHER
        </li>
      </ul>
    </div>
  );
};

export default withRouter(NavList);
