import React, { useEffect, useState, useLayoutEffect } from "react";
import { Treatment } from "../../../utils/data";
import { Augmentation } from "../../../utils/data";
import { Rejuvenation } from "../../../utils/data";
import { Dermatology } from "../../../utils/data";
import { JointsandTendons } from "../../../utils/data";
import { Other } from "../../../utils/data";
import { ForMen } from "../../../utils/data";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import { makeStyles, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import DefaultImg from "../../../assets/image/menulink/shutterstock_1123974401.png";
import MyTooltip from "../../Common/MyTooltip";
import Button from "@material-ui/core/Button";
import Biotin from "../../../assets/image/menulink/Biotin.jpg";
import vitaminDInjections from "../../../assets/image/menulink/vitamin D injections.jpg";
import Profilo from "../../../assets/image/menulink/Profilo.jpg";
import Jalupro from "../../../assets/image/menulink/Jalupro.jpg";
import { connect } from "react-redux";
import { START_GET_PDF_URL } from "../../../actions/main";
const useStyle = makeStyles((theme) => ({
  newListinSamediv: {
    marginTop: 30,
  },
  Bookbtn: {
    margin: "30px auto",
    [theme.breakpoints.only("md")]: {
      margin: 0,
    },
    "& button": {
      margin: "auto 10px",
      fontFamily: "GothicRegular",
      fontSize: ".85rem",
      [theme.breakpoints.only("md")]: {
        margin: "auto 5px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
    },
  },
  subNavMenu: {
    minWidth: "60vw",
    height: "100%",
    "& .MuiDivider-root": {
      height: "2px",
      margin: "5px",
      width: "70%",
      backgroundColor: theme.palette.primary.main,
    },
  },
  mainCategory: {
    color: theme.palette.secondary.main,
  },
  subNavMenuDiv: {
    width: "70%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    //marginTop: 20,
    "& div": {
      //width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      margin: "5px auto",
      width: "100%",
      textAlign: "center",
      "& div": {
        // width: "100%",
        margin: "8px 0",
      },
    },
  },
  subNavMenuDivul: {
    padding: 0,
    "& li": {
      listStyle: "none",
      color: theme.palette.primary.main,
      fontSize: "0.8333rem",
      marginBottom: "7px",
      minInlineSize: "max-content",
      cursor: "pointer",
      transition: "opacity .3s ease-in-out",
      "&:hover": {
        opacity: "100% !important",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "10px",
        fontSize: "0.65rem",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
        margin: "15px 0px",
      },
    },
  },
  subNavMenuDivInnerh3: {
    color: theme.palette.secondary.main,
    marginBlockStart: "0",
    fontSize: "0.8333rem",
    marginBottom: "7px",
    [theme.breakpoints.only("md")]: {
      fontSize: "0.7rem",
    },
  },
  subNavMenuDivInner: {
    width: "100%",
  },
  treatment: {
    "& ul": {
      columnCount: "1",
    },
  },
  divider: {
    display: "flex",
    justifyContent: "center",
    "& hr": {
      width: "70%",
      height: "2px",
      margin: "5px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  comingSoon: {
    marginLeft: "10px",
    fontSize: ".55rem",
    color: "white",
    height: "12px",
    "& .MuiChip-labelSmall": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  headinOfList: {
    marginTop: "15px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px !important",
    },
  },
  colGrid: {
    alignItems: "flex-start",
    height: "75%",
    justifyContent: "center",
    "@media (max-width: 1366px)": {
      height: "auto",
    },
    "@media (max-width: 1920px)": {
      // height: "auto",
    },
  },
  navbarcollapse: {
    display: "none",
  },
  hovered: {
    opacity: "50%",
  },
  subNavMenuDivOutter: {
    display: "grid",
    height: "100%",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "18%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "unset",
      rowGap: "1%",
    },
  },
  hoverImgContainer: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    flexDirection: "column",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      width: "100%",
    },
  },
  hoverImg: {
    width: "60%",
    height: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
    marginTop: "20px",
    marginLeft: "30px",
  },
  defaultImgContainer: {
    position: "absolute",
    right: "0px",
    bottom: -4,
    width: "45%",
    [theme.breakpoints.up("xl")]: {
      width: "60%",
    },
  },
  defaultImg: {
    width: "100%",
    height: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
    // marginTop :"75px"
  },
  hidePara: {
    display: "none",
  },
  displayPara: {
    display: "block",
  },
  treatmentPriceListDiv: {
    margin: "auto",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "100px",

    [theme.breakpoints.down("xs")]: {
      width: "100%", // on Tab 0px
      paddingBottom: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // on Tab 0px
      paddingBottom: "50px",
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "100%", // on Tab 0px
    // },
  },
  treatmentDivUl: {
    // marginTop: "390px", // on Tab 0px
    "& li": {
      listStyle: "none",
      color: theme.palette.primary.main,
      fontSize: "0.8333rem",
      marginBottom: "7px",
      minInlineSize: "max-content",
      cursor: "pointer",
      transition: "opacity .3s ease-in-out",
      "&:hover": {
        opacity: "100% !important",
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "10px",
        fontSize: "0.65rem",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
        margin: "15px 0px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      marginTop: "1px", // on Tab 0px
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-30px",
      marginTop: "1px", // on Tab 0px
    },
  },
  PriceListDiv: {
    margin: "auto",
    width: "50%",
    paddingBottom: "100px",
    "@media (max-width: 1920px)": {
      marginBottom: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "50px",
    },
  },
  GiftSendDiv: {
    margin: "auto",
    width: "50%",
    paddingBottom: "100px",
    marginLeft: "35px",
    "@media (max-width: 1920px)": {
      marginBottom: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "50px",
    },
  },
}));

const SubNavList = ({
  pdf_data_status,
  getpdfurl,
  topOpen,
  isNavDrawerOpen,
  history,
  closeMenuFunc,
}) => {
  const classes = useStyle();
  const [openNav, setOpenNav] = useState(true);
  const [Hovered, setHovered] = useState(false);
  const [hoverImage, setHoverImage] = useState("");
  useEffect(() => {
    if (topOpen === "condition") {
      setOpenNav(true);
    } else {
      setOpenNav(false);
    }
  }, [topOpen]);

  useEffect(() => {
    getpdfurl();
  }, [getpdfurl]);

  function HoverEffect(hoverimg) {
    setHovered(true);
    setHoverImage(hoverimg); //TODO : Set hover image
  }
  function HoveroutEffect() {
    setHovered(false);
    setHoverImage(""); //TODO : Remove hover image
  }
  function subNavListOnclick(src) {
    closeMenuFunc();
    if (src === "") {
      return;
    } else {
      history.push(`/${src}`);
    }
  }
  const [openPara1, setOpenPara1] = useState(false);
  const [openPara2, setOpenPara2] = useState(false);
  const [openPara3, setOpenPara3] = useState(false);
  const [openPara4, setOpenPara4] = useState(false);
  const [openPara5, setOpenPara5] = useState(false);
  const [width, setInnerWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleOnClickPara = (name) => {
    if (width < 860) {
      if (name === "para1") {
        setOpenPara1(!openPara1);
        setOpenPara2(false);
        setOpenPara3(false);
        setOpenPara4(false);
        setOpenPara5(false);
      }
      if (name === "para2") {
        setOpenPara2(!openPara2);
        setOpenPara1(false);
        setOpenPara3(false);
        setOpenPara4(false);
        setOpenPara5(false);
      }
      if (name === "para3") {
        setOpenPara3(!openPara3);
        setOpenPara1(false);
        setOpenPara2(false);
        setOpenPara4(false);
        setOpenPara5(false);
      }
      if (name === "para4") {
        setOpenPara4(!openPara4);
        setOpenPara1(false);
        setOpenPara2(false);
        setOpenPara3(false);
        setOpenPara5(false);
      }
      if (name === "para5") {
        setOpenPara5(!openPara5);
        setOpenPara1(false);
        setOpenPara2(false);
        setOpenPara3(false);
        setOpenPara4(false);
      }
    }
  };
  return (
    <Slide
      in={isNavDrawerOpen}
      direction="up"
      className={clsx({
        [classes.navbarcollapse]: !isNavDrawerOpen,
      })}
      timeout={{ enter: 300, exit: 300 }}
    >
      <div className={classes.subNavMenu}>
        <div className={classes.divider}>
          <Divider />
        </div>
        {openNav && (
          <Fade
            in={openNav}
            className={classes.colGrid}
            timeout={400}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.subNavMenuDiv}>
                <div className={classes.subNavMenuDivOutter}>
                  <div className={classes.subNavMenuDivInner}>
                    <ul className={classes.subNavMenuDivul}>
                      <div onClick={() => handleOnClickPara("para1")}>
                        <h3 className={classes.subNavMenuDivInnerh3}>
                          Augmentation
                        </h3>
                      </div>
                      {Augmentation.map((dataObj, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            subNavListOnclick(dataObj.src);
                          }}
                          onMouseEnter={() => HoverEffect(dataObj)}
                          onMouseLeave={() => HoveroutEffect()}
                          // className={clsx({ [classes.hovered]: Hovered })}
                          className={clsx({
                            [classes.hidePara]: !openPara1 && width < 860,
                            [classes.displayPara]: openPara1 && width < 860,
                            [classes.hovered]: Hovered,
                          })}
                        >
                          {dataObj.name}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className={classes.subNavMenuDivInner}>
                    <ul className={classes.subNavMenuDivul}>
                      <div onClick={() => handleOnClickPara("para2")}>
                        <h3
                          className={clsx(
                            classes.headinOfList,
                            classes.subNavMenuDivInnerh3
                          )}
                        >
                          Rejuvenation
                        </h3>
                      </div>
                      {Rejuvenation.map((dataObj, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            subNavListOnclick(dataObj.src);
                          }}
                          onMouseEnter={() => HoverEffect()}
                          onMouseLeave={() => HoveroutEffect()}
                          className={clsx({
                            [classes.hidePara]: !openPara2 && width < 860,
                            [classes.displayPara]: openPara2 && width < 860,
                            [classes.hovered]: Hovered,
                          })}
                        >
                          {dataObj.name}
                        </li>
                      ))}
                      <li
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        onMouseEnter={() => HoverEffect()}
                        onMouseLeave={() => HoveroutEffect()}
                        className={clsx({
                          [classes.hidePara]: !openPara2 && width < 860,
                          [classes.displayPara]: openPara2 && width < 860,
                          [classes.hovered]: Hovered,
                        })}
                      >
                        Skin Boosters
                      </li>
                    </ul>
                    <ul
                      className={clsx(
                        classes.newListinSamediv,
                        classes.subNavMenuDivul
                      )}
                    >
                      <div onClick={() => handleOnClickPara("para3")}>
                        <h3
                          className={clsx(
                            classes.headinOfList,
                            classes.subNavMenuDivInnerh3
                          )}
                        >
                          Dermatology
                        </h3>
                      </div>
                      {Dermatology.map((dataObj, index) => (
                        <li
                          // style={{
                          //   pointerEvents: dataObj.src === "" ? "none" : "",
                          //  }}
                          key={index}
                          onClick={() => {
                            dataObj.src === ""
                              ? console.log("U Clicked")
                              : subNavListOnclick(dataObj.src);
                          }}
                          onMouseEnter={() => HoverEffect()}
                          onMouseLeave={() => HoveroutEffect()}
                          className={clsx({
                            [classes.hidePara]: !openPara3 && width < 860,
                            [classes.displayPara]: openPara3 && width < 860,
                            [classes.hovered]: Hovered,
                          })}
                        >
                          {dataObj.comingSoon ? (
                            <MyTooltip title={"Coming Soon"}>
                              <span>{dataObj.name}</span>
                            </MyTooltip>
                          ) : (
                            dataObj.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className={classes.subNavMenuDivInner}>
                    <ul className={classes.subNavMenuDivul}>
                      <div onClick={() => handleOnClickPara("para4")}>
                        <h3 className={classes.subNavMenuDivInnerh3}>
                          For Men
                        </h3>
                      </div>
                      {ForMen.map((dataObj, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            subNavListOnclick(dataObj.src);
                          }}
                          onMouseEnter={() => HoverEffect()}
                          onMouseLeave={() => HoveroutEffect()}
                          style={{
                            minInlineSize: "unset",
                            width: "150px",
                            lineHeight: "1.4",
                          }}
                          className={clsx({
                            [classes.hidePara]: !openPara4 && width < 860,
                            [classes.displayPara]: openPara4 && width < 860,
                            [classes.hovered]: Hovered,
                          })}
                        >
                          {dataObj.name}
                        </li>
                      ))}
                    </ul>
                    <ul
                      className={clsx(
                        classes.newListinSamediv,
                        classes.subNavMenuDivul
                      )}
                    >
                      <div onClick={() => handleOnClickPara("para5")}>
                        <h3
                          className={clsx(
                            classes.headinOfList,
                            classes.subNavMenuDivInnerh3
                          )}
                        >
                          Other
                        </h3>
                      </div>
                      {Other.map((dataObj, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            subNavListOnclick(dataObj.src);
                          }}
                          onMouseEnter={() => HoverEffect()}
                          onMouseLeave={() => HoveroutEffect()}
                          className={clsx({
                            [classes.hidePara]: !openPara5 && width < 860,
                            [classes.displayPara]: openPara5 && width < 860,
                            [classes.hovered]: Hovered,
                          })}
                        >
                          {dataObj.name}
                        </li>
                      ))}
                    </ul>
                    <ul
                      className={clsx(
                        classes.newListinSamediv,
                        classes.subNavMenuDivul
                      )}
                    >
                      <div onClick={() => handleOnClickPara("para5")}>
                        <h3
                          className={clsx(
                            classes.headinOfList,
                            classes.subNavMenuDivInnerh3
                          )}
                        >
                          Joints & Tendons
                        </h3>
                      </div>
                      {JointsandTendons.map((dataObj, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            subNavListOnclick(dataObj.src);
                          }}
                          onMouseEnter={() => HoverEffect()}
                          onMouseLeave={() => HoveroutEffect()}
                          className={clsx({
                            [classes.hidePara]: !openPara5 && width < 860,
                            [classes.displayPara]: openPara5 && width < 860,
                            [classes.hovered]: Hovered,
                          })}
                        >
                          {dataObj.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    marginBottom: "26px",
                  }}
                >
                  {/* //! Add Button Here          */}
                  {width > 800 && (
                    <div className={classes.PriceListDiv}>
                      <Button
                        variant="contained"
                        className={classes.bookBtn}
                        color="secondary"
                        disableElevation
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        classes={{
                          root: classes.buttonsHeightWidth,
                        }}
                      >
                        Price List
                      </Button>
                    </div>
                  )}
                  {width > 800 && (
                    <div className={classes.GiftSendDiv}>
                      <Button
                        variant="contained"
                        className={classes.bookBtn}
                        color="secondary"
                        disableElevation
                        onClick={() => {
                          // history.push("/gift");
                          window.location.href =
                          "https://partner.pabau.com/online-bookings/DPA-Clinics?prepaidType=voucher&prepaidFlow=true&medicalForms=true";
                        }}
                        classes={{
                          root: classes.buttonsHeightWidth,
                        }}
                      >
                        Send a Gift
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Fade>
        )}
        {!openNav && (
          <Fade in={!openNav} timeout={400} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div
                className={clsx(classes.subNavMenuDiv, classes.treatment)}
                style={{
                  width: "100%",
                  justifyContent: "space-evenly",
                  position: "relative",
                }}
              >
                <ul
                  className={clsx(classes.treatment, classes.treatmentDivUl)}
                  style={{
                    left: width > 860 ? "9%" : undefined,
                    position: width > 860 ? "absolute" : undefined,
                    top: 0,
                  }}
                >
                  {Treatment.map((list, index) => (
                    <React.Fragment key={index}>
                      <h3 key={index}>{list.key}</h3>
                      {list.data.map((subCategory, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              subNavListOnclick(subCategory.src);
                            }}
                            onMouseEnter={() =>
                              HoverEffect(subCategory.menuimage)
                            }
                            onMouseLeave={() => HoveroutEffect()}
                            className={clsx({ [classes.hovered]: Hovered })}
                          >
                            {subCategory.name}
                          </li>
                        );
                      })}
                      <li
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        onMouseEnter={() => HoverEffect(Biotin)}
                        onMouseLeave={() => HoveroutEffect()}
                        className={clsx({
                          [classes.hovered]: Hovered,
                        })}
                      >
                        Biotin Injections
                      </li>
                      <li
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        onMouseEnter={() => HoverEffect(vitaminDInjections)}
                        onMouseLeave={() => HoveroutEffect()}
                        className={clsx({
                          [classes.hovered]: Hovered,
                        })}
                      >
                        Vitamin D Injections
                      </li>
                      <li
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        onMouseEnter={() => HoverEffect(Profilo)}
                        onMouseLeave={() => HoveroutEffect()}
                        className={clsx({
                          [classes.hovered]: Hovered,
                        })}
                      >
                        Profhilo®
                      </li>
                      <li
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        onMouseEnter={() => HoverEffect(Jalupro)}
                        onMouseLeave={() => HoveroutEffect()}
                        className={clsx({
                          [classes.hovered]: Hovered,
                        })}
                      >
                        Jalupro®
                      </li>
                    </React.Fragment>
                  ))}
                  {width > 800 && (
                    <div className={classes.treatmentPriceListDiv}>
                      <Button
                        variant="contained"
                        className={classes.bookBtn}
                        color="secondary"
                        disableElevation
                        onClick={() => {
                          window.open(pdf_data_status.pdf_data.pricepdf_url);
                        }}
                        classes={{
                          root: classes.buttonsHeightWidth,
                        }}
                      >
                        Price List
                      </Button>
                    </div>
                  )}
                </ul>
              </div>

              {/* //! Rendered in Drawer Image  */}
              <div className={classes.hoverImgContainer}>
                {Hovered ? (
                  <Fade in={Hovered} timeout={250} unmountOnExit={true}>
                    {hoverImage !== "" || true ? (
                      <img
                        src={hoverImage}
                        className={classes.hoverImg}
                        alt="Hover Img"
                      />
                    ) : (
                      <></>
                    )}
                  </Fade>
                ) : (
                  <div className={classes.defaultImgContainer}>
                    <img
                      src={DefaultImg}
                      className={classes.defaultImg}
                      alt="Default Img"
                    ></img>
                  </div>
                )}
              </div>
            </Grid>
          </Fade>
        )}
      </div>
    </Slide>
  );
};

const mapStateToProps = (state) => ({
  pdf_data_status: state.main.pdf_data_status,
});

const mapDispatchToProps = (dispatch) => ({
  getpdfurl: () =>
    dispatch({
      type: START_GET_PDF_URL,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubNavList));
