import React, { useState, useEffect, useCallback, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PageNotFound from "../src/components/PageNotFound";
import PopUpModal from "../src/components/Common/PopUpModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router-dom";
//MainPages
import LandingAnimation from "./components/Common/LandingAnimation";
import LandingPage from "./containers/LandingPage";
import { connect } from "react-redux";
import {
  START_CHECK_FOR_OFFERS,
  START_SUBSCRIBE_USER,
  SET_FALSE,
  SET_FALSE_GIFT_VOUCHER,
  SET_FALSE_TESTIMONIAL,
} from "./actions/main";
import { useSnackbar } from "notistack";
import PDF from "./containers/PDF";
import DpaPdf from "./containers/DpaPdf";
import AugmentationRoutes from "./components/SubPages/Routes/AugmentationRoutes";
import Loading from "./components/Loading";
import RejuvenationRoutes from "./components/SubPages/Routes/RejuvenationRoutes";
import DermatologyRoutes from "./components/SubPages/Routes/DermatologyRoutes";
import AllMenAndOtherRoutes from "./components/SubPages/Routes/AllMenAndOtherRoutes";
import AllTreatmentsRoutes from "./components/SubPages/Routes/AllTreatmentsRoutes";
import LoadAssets from "./containers/LoadAssets";
const GiftVocucher = React.lazy(() =>
  import("./components/SubPages/GiftVocucher")
);
const TestimonialView = React.lazy(() =>
  import("./components/SubPages/TestimonialView")
);

const AfterCare = React.lazy(() => import("./components/AfterCare"));
const BookConsultation = React.lazy(() =>
  import("./components/BookConsultation")
);
const Offers = React.lazy(() => import("./components/Offers"));
const MeetDrPalinMain = React.lazy(() =>
  import("./components/MeetDrPalinMainNew")
);
const SuccessStoriesMain = React.lazy(() =>
  import("./components/SuccessStoriesMain")
);
const ContactUs = React.lazy(() => import("./components/ContactUs"));
//SubPages
//condition
const HairlossCopy = React.lazy(() =>
  import("./components/SubPages/HairlossCopy")
);
const GoldenRatio = React.lazy(() =>
  import("./components/SubPages/GoldenRatio")
);
//Treatment
const VampireFacelift = React.lazy(() =>
  import("./components/SubPages/VampireFacelift")
);
const GiftVocucherView = React.lazy(() =>
  import("./components/SubPages/GiftVocucherView")
);

const Main = (props) => {
  const {
    offers,
    subscribeuser,
    message,
    variant,
    subscriber_actions,
    setIsSubscribedFalse,
    setIsGiftVoucherSendFalse,
    setIsTestimonalSendFalse,
  } = props;

  const [showLandingAnimation, setShowLandingAnimation] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [OnPageChange, setOnPageChange] = useState(false);
  const [onPageNo, setOnPageNo] = useState(0);
  const [ifOffer, setIfOffer] = useState(true);

  const [OpenPopUpModal, setOpenPopUpModal] = useState(false);
  const [whichModal, setwhichModal] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  function completeAnimate() {
    setShowLandingAnimation(false);
  }

  function openMenuFunc() {
    setIsMenuOpen(!isMenuOpen);
  }

  function closeMenuFunc() {
    setIsMenuOpen(false);
  }

  function onChangePageHandle(number) {
    if (onPageNo === number) {
      setOnPageChange(false);
      setOnPageNo(number);
    } else {
      setOnPageNo(number);
      setOnPageChange(true);
    }
  }

  function OpenPopUpModalFunc(data) {
    setwhichModalfunc(data);
    setOpenPopUpModal(true);
  }

  function closePopUpModalFunc() {
    setwhichModalfunc("");
    setOpenPopUpModal(false);
  }

  function setwhichModalfunc(data) {
    setwhichModal(data);
  }

  useEffect(() => {
    if (location.pathname !== "/") {
      setShowLandingAnimation(false);
    }
  }, []);

  function redirectFunc(url) {
    props.history.push(`/${url}`);
  }

  function enternalLinkFunc(url) {
    window.open(url);
  }

  useEffect(() => {
    //check for offers
    const { checkforoffers } = props;
    checkforoffers();
  }, []);

  const [isOffersDrawerOpenedOnce, setIsOffersDrawerOpenedOnce] = useState(
    false
  );

  const setOffers = useCallback(() => {
    if (offers.isAvailable && !ifOffer) {
      setIfOffer(true);
    } else {
      setIfOffer(false);
    }
  }, [offers.isAvailable]);

  useEffect(() => {
    setOffers();
  }, [offers]);

  //notistack
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (message && variant && message !== "" && variant !== "") {
      enqueueSnackbar(message, { variant });
    }
  }, [message, variant]);

  //test check

  return (
    <Suspense fallback={<Loading />}>
      <LoadAssets>
        <PopUpModal
          whichModal={whichModal}
          closePopUpModalFunc={closePopUpModalFunc}
          closeMainMenuFunc={closeMenuFunc}
          OpenPopUpModal={OpenPopUpModal}
        />
        {showLandingAnimation === undefined ? (
          <div></div>
        ) : showLandingAnimation === false ? (
          <Switch>
            <Route exact path="/">
              <LandingPage
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                OnPageChange={OnPageChange}
                isMenuOpen={isMenuOpen}
                closeMenuFunc={closeMenuFunc}
                onChangePageHandle={onChangePageHandle}
                openMenuFunc={openMenuFunc}
                ifOffer={ifOffer}
                isOffersDrawerOpenedOnce={isOffersDrawerOpenedOnce}
                setIsOffersDrawerOpenedOnce={setIsOffersDrawerOpenedOnce}
                offers={offers}
              />
            </Route>
            <Route path="/gift" exact>
              <GiftVocucher
                ifoffer={ifOffer}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                setIsGiftVoucherSendFalse={setIsGiftVoucherSendFalse}
              />
            </Route>
            <Route path="/gift/view/:id/:type" exact>
              <GiftVocucherView
                ifoffer={ifOffer}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                setIsGiftVoucherSendFalse={setIsGiftVoucherSendFalse}
              />
            </Route>
            <Route path="/request-testimonial/:id" exact>
              <TestimonialView
                ifoffer={ifOffer}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                setIsTestimonalSendFalse={setIsTestimonalSendFalse}
              />
            </Route>
            <Route path="/price-list-pdf" exact>
              <PDF />
            </Route>
            <Route path="/dpa-pdf" exact component={DpaPdf} />
            <Route path="/after-care" exact>
              <AfterCare
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            <Route path="/book-consultation" exact>
              <BookConsultation
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            {/* <Route path="/offers" exact>
              <Offers
                offers={offers}
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
                subscribeuser={subscribeuser}
                subscriber_actions={subscriber_actions}
                setIsSubscribedFalse={setIsSubscribedFalse}
              />
            </Route> */}
            <Route path="/meet-dr-palin" exact>
              <MeetDrPalinMain
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            <Route path="/contact-us" exact>
              <ContactUs
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            <Route path="/success-stories" exact>
              <SuccessStoriesMain
                ifoffer={ifOffer}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
              />
            </Route>
            <Route path="/golden-ratio" exact>
              <GoldenRatio
                redirectFunc={redirectFunc}
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            <Route path="/paltelet-rich-plasma" exact>
              <VampireFacelift
                redirectFunc={redirectFunc}
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            <Route path="/hairloss-treatment-og" exact>
              <HairlossCopy
                enternalLinkFunc={enternalLinkFunc}
                redirectFunc={redirectFunc}
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
            {AugmentationRoutes(
              redirectFunc,
              OpenPopUpModal,
              OpenPopUpModalFunc,
              ifOffer
            )}
            {RejuvenationRoutes(
              redirectFunc,
              OpenPopUpModal,
              OpenPopUpModalFunc,
              ifOffer,
              enternalLinkFunc
            )}
            {DermatologyRoutes(
              redirectFunc,
              OpenPopUpModal,
              OpenPopUpModalFunc,
              ifOffer
            )}
            {AllMenAndOtherRoutes(
              redirectFunc,
              OpenPopUpModal,
              OpenPopUpModalFunc,
              ifOffer,
              enternalLinkFunc
            )}
            {AllTreatmentsRoutes(
              redirectFunc,
              OpenPopUpModal,
              OpenPopUpModalFunc,
              ifOffer,
              enternalLinkFunc
            )}
            <Route path="*" exact>
              <PageNotFound
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
                ifoffer={ifOffer}
              />
            </Route>
          </Switch>
        ) : showLandingAnimation === true ? (
          <LandingAnimation completeAnimate={completeAnimate} />
        ) : null}
      </LoadAssets>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  offers: state.main.offers,
  message: state.main.message,
  variant: state.main.variant,
  subscriber_actions: state.main.subscriber_actions,
});

const mapDispatchToProps = (dispatch) => ({
  checkforoffers: () =>
    dispatch({
      type: START_CHECK_FOR_OFFERS,
    }),
  subscribeuser: (email) =>
    dispatch({
      type: START_SUBSCRIBE_USER,
      email,
    }),
  setIsSubscribedFalse: () =>
    dispatch({
      type: SET_FALSE,
    }),
  setIsGiftVoucherSendFalse: () =>
    dispatch({
      type: SET_FALSE_GIFT_VOUCHER,
    }),
  setIsTestimonalSendFalse: () =>
    dispatch({
      type: SET_FALSE_TESTIMONIAL,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
