import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import quotesGold from "../../assets/quotesGold.png";

import img8 from "../../assets/image/Lisa-Brown.jpeg";
import img4 from "../../assets/image/Athene.jpg";
import img6 from "../../assets/image/Grace-Brine.jpg";

import ReviewCard from "./ReviewCard";
import FootDivider from "../Common/FootDivider";
import { useIntersection } from "../../utils/LazyLoadingHook";
import { withRouter } from "react-router-dom";
import MyTypograph from "../../components/Common/MyTypograph";
import VideoBhavnaPatel from "../VideoBhavnaPatel";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const Width = "80%";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "#fbf9f7",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "85px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "100px",
    },
  },
  MoreBtn: {
    fontSize: ".65rem",
    color: "white !important",
    // [theme.breakpoints.up("xl")] : {
    //     width: "10%",
    //     height: "40px",
    //     fontSize: "1rem",
    // }
  },
  storyTitle: {
    display: "flex",
    marginTop: 60,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      margin: "25px 0px",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 30,
    },
  },
  storyTitleText: {
    color: "#baa07c",
    fontFamily: "GothicRegular",
    letterSpacing: "5px",
    fontSize: "1.35rem",
    [theme.breakpoints.only("md")]: {
      fontSize: ".85rem",
    },
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
  },
  videoReview: {
    width: Width,
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px 20px",
    },
  },
  videoReviewiframe: {
    width: "100%",
    height: "100%",
  },
  TextDiv: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  Text: {
    alignSelf: "center",
    fontFamily: "GothicRegular",
    fontSize: "1.25rem",
    width: "75%",
    placeSelf: "center",
    textAlign: "center",
    lineHeight: 1.75,
    [theme.breakpoints.only("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.15rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      width: "50%",
      padding: "10px 0px",
    },
  },
  TextDivInnerDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0 !important",
    },
  },
  TextDivInnerDivImg: {
    height: "21% !important",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  quotesGold: {
    height: 20,
    alignSelf: "flex-end",
    [theme.breakpoints.only("md")]: {
      height: 15,
    },
  },
  videoReviewName: {
    fontSize: "1.25rem",
    fontFamily: "CenturyGothicBold",
    [theme.breakpoints.only("md")]: {
      fontSize: "1.05rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  textReview: {
    display: "flex",
    width: Width,
    margin: 0,
    justifyContent: "space-between",
    height: "567px",
    padding: "30px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  MoreBtnDiv: {
    display: "flex",
    width: "85%",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "120px",
    paddingTop: " 20px",
    "& button": {
      width: "15%",
      fontSize: ".95rem",
      alignSelf: "baseline",
      [theme.breakpoints.only("md")]: {
        width: "20%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        width: "40%",
        fontSize: ".7rem",
        alignSelf: "baseline",
      },
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: " 50px",
    },
  },
  footDivider: {
    backgroundColor: theme.palette.secondary.main,
    left: "0",
    width: "100%",
    bottom: "0",
    position: "absolute",
    height: "15px",
    [theme.breakpoints.up("xl")]: {
      height: "30px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  videoReviewTextReview: {
    height: "58% !important",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100% !important",
      padding: "10px 0px",
    },
  },
  videoReviewTextName: {
    height: "21% !important",
    width: "100%",
    textAlign: "center",
  },
  successStoriesText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      letterSpacing: "0.4rem",
      top: 0,
      margin: "15px 0px",
    },
  },
  btnDiv: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "20px 0px",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      margin: "30px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      // display: "none",
      // margin: "-25px -5px",
      display: "none",
      // position: 'relative',
      // right: '118px',
      // bottom: '20px'
    },
  },
  buttonRoot: {
    borderRadius: 0,
    padding: "1px 18px",
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      animation: "none",
      "& *": {
        color: "white !important",
      },
    },
  },
  buttonLabel: {
    color: theme.palette.primary.main,
    fontSize: ".84rem !important",
    letterSpacing: "0.2rem",
  },
  scrollButton: {
    width: "70px",
    borderRadius: 0,
    borderWidth: "1px !important",
    //paddingTop: 2,
    //paddingBottom: 2,
    padding: 0,
    marginLeft: "auto",
    right: 50,
    top: 10,
    position: "absolute",
    animation: "$intropic 3s infinite",
    boxShadow: "0px 0px 0px 0px rgba(186,160,121,0.6)",
    "&>span": {
      "&>span": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      animation: "none",
      "& *": {
        color: "white !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      //display: "none",
    },
  },
  "@keyframes intropic": {
    "0%": {
      transform: "scale(.9)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0px 0px 0px 50px rgba(186,160,121,0)",
    },
    "100%": {
      transform: "scale(.9)",
      boxShadow: "0px 0px 0px 0px rgba(186,160,121,0)",
    },
  },
  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: theme.palette.primary.main,
    transition: `all 250ms`,
  },
}));

const reviewData = [
  {
    name: "Lisa Brown",
    city: "Manchester",
    rating: 5,
    imgUrl: img8,
    review: `He understands that it’s not always the blaringly obvious but sometimes it’s the little imperfections that are more noticeable to me.`,
  },
  {
    name: "Athene Hibbert",
    city: "Cheadle Hulme",
    rating: 5,
    imgUrl: img4,
    review: `The procedure was completely pain free, so much so that I was unaware of the needles going in to my skin. His knowledge and experience gave me all the confidence I needed. I wouldn’t go to any other doctor in the future.`,
  },
  {
    name: "Grace Brine",
    city: "Cheadle Hulme",
    rating: 5,
    imgUrl: img6,
    review: `Dr Palin dissolved my lumpy filler in my lips. He is professional and really takes time and care with the treatment. I’m so happy with the results and I can’t wait to have them touched up by him.`,
  },
];

const SuccessStories = ({ history, width, handlePageChange, pageNo }) => {
  const classes = useStyles();
  const [setTarget, entry] = useIntersection({ threshold: 0.3 });
  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    const { intersectionRatio } = entry;
    if (intersectionRatio >= 0.3) {
      //console.log("Hello")
      setFadeIn(true);
    }
  }, [entry]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pabau.com/widgets/pabau-reviews.js?slug=DPA-Clinics";
    script.async = true;
    document.getElementById("pabReviews").appendChild(script);
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.btnDiv}>
        <Button
          variant="outlined"
          classes={{
            root: classes.scrollButton,
            label: classes.buttonLabel,
          }}
          onClick={(e) => handlePageChange(pageNo - 1)}
          endIcon={
            <KeyboardArrowUpIcon
              classes={{
                root: classes.iconDownRoot,
              }}
            />
          }
          tabIndex={"0"}
          disableFocusRipple
        ></Button>
      </div>
      <div className={classes.storyTitle}>
        <MyTypograph variant="Title" className={classes.successStoriesText}>
          Success Stories
        </MyTypograph>
      </div>
      <Grid container className={classes.videoReview}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          xl={7}
          className={classes.videoReviewMedia}
        >
          <VideoBhavnaPatel />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={classes.TextDiv}
        >
          <div className={classes.TextDivInnerDiv}>
            <div className={classes.TextDivInnerDivImg}>
              <img alt="" className={classes.quotesGold} src={quotesGold} />
            </div>
            <div className={classes.videoReviewTextReview}>
              <Typography className={classes.Text}>
                It’s reassuring to have my treatments done by a neurosurgeon who
                has in-depth knowledge of facial anatomy and a great eye for
                aesthetics.
              </Typography>
            </div>
            <div className={classes.videoReviewTextName}>
              <Typography color="primary" className={classes.videoReviewName}>
                Bhavna Patel
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        ref={setTarget}
        className={classes.textReview}
      >
        {/* {reviewData.map((data, index) => {
          if (width > 860) {
            return (
              <ReviewCard
                fadeIn={fadeIn}
                index={index}
                key={index}
                data={data}
              />
            );
          } else {
            if (index !== 2) {
              return (
                <ReviewCard
                  fadeIn={fadeIn}
                  index={index}
                  key={index}
                  data={data}
                />
              );
            }
          }
        })} */}
        <div className="pabauReviews" id="pabReviews" style={{ width: "100%" }}>
          <script src="https://pabau.com/widgets/pabau-reviews.js?slug=DPA-Clinics"></script>
        </div>
      </Grid>
      <div className={classes.MoreBtnDiv}>
        <Button
          classes={{ root: classes.MoreBtn }}
          variant="contained"
          onClick={() => history.push("/success-stories")}
          color="primary"
        >
          {" "}
          More Stories{" "}
        </Button>
      </div>
      <FootDivider />
    </div>
  );
};

export default withRouter(SuccessStories);
