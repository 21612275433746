import React from "react";
import { makeStyles, darken } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import SocialMediaIconCommon from "./SocialMediaIconCommon";
import ReLoadGif from "./ReLoadGif";
import Partners from "./Partners";
import clsx from "clsx";
//import signGif from '../../assets/Signature.gif';
import { withRouter, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    width: "100%",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    [theme.breakpoints.only("xs")]: {
      // marginTop: "10px",
    },
  },
  footerContainerWhite: {
    background: "white",
  },
  footerBookBtn: {
    color: "white",
    display: "flex",
    backgroundColor: "#c2a472",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 30,
    // [theme.breakpoints.only("md")] : {
    //     padding: "5px",
    //     rowGap: "10px",
    // },
  },
  footerBookOtherPageBtn: {
    color: "white",
    display: "flex",
    backgroundColor: "#c2a472",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 30,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 15,
    },
  },
  bookBtn: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    minWidth: 240,
    transform: "scale(0.95)",
    "& span": {
      [theme.breakpoints.up("xl")]: {
        fontSize: ".75rem",
      },
      fontSize: ".85rem",
    },
    "&:hover": {
      backgroundColor: darken("#ffffff", 0.04),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  lineOne: {
    fontFamily: "GothicRegular",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.55rem",
    },
    fontSize: "1.15rem",
    [theme.breakpoints.only("md")]: {
      fontSize: ".85rem",
    },
  },
  togetherPara: {
    fontFamily: "Open Sans",
    textTransform: "uppercase",
    fontSize: "1.15rem",
    fontWeight: "bold",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.55rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: ".85rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: "0 26px !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "0 20px !important",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "25px",

    [theme.breakpoints.down("sm")]: {
      padding: "32px 12px !important",
      //marginBottom: "60px !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 12px !important",
      //marginBottom: "80px !important",
    },
  },
  footerInVh: {
    padding: 0,
  },
  footerCopyRight: {
    fontFamily: "GothicRegular",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: ".9rem",
    },
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      // "& span": {
      //   display: "none",
      // },
    },
  },
  developedByCls: {
    fontFamily: "GothicRegular",
    fontSize: "1rem",
    "&>a": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "none",
    },
    "&:hover": {
      fontFamily: "CenturyGothicBold",
    },
  },
  mobFooterCopyRight: {
    fontFamily: "GothicRegular",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: ".9rem",
    },
    fontSize: ".8rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  footerNavBar: {
    display: "flex",
    color: theme.palette.primary.main,
    padding: 13,
    "& ul": {
      padding: 0,
      listStyle: "none",
      justifyContent: "center",
      margin: "0.50rem",
      "& li": {
        display: "inline-block",
        fontSize: ".70rem",
        fontFamily: "CenturyGothicBold",
        cursor: "pointer",
        // [theme.breakpoints.up("xl")] : {
        //     fontSize: ".85rem",
        // },
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "12px !important",
      "& ul>li>span": {
        fontSize: `.85rem`,
        textAlign: "center",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
      "& ul>li>span": {
        fontSize: `.6rem`,
        textAlign: "center",
      },
      "& ul": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  footMenu: {
    padding: "5px",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: "0px",
    },
  },
  displayNone: {
    display: "none !important",
  },
  signGif: {
    width: "31%",
    minHeight: "110px",
    "& img": {
      height: "auto",
      width: "65%",
      objectFit: "cover",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px",
      width: "auto",
      "& img": {
        height: "110px",
        width: "220px",
        objectFit: "cover",
      },
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "95px",
    },
  },
  isNotSignFooterTop: {
    padding: "0 0 8px",
  },
  bookConsultationPara: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  isNotSignFooter: {
    padding: "8px 0",
  },
  isNotSignFooterBookButton: {
    padding: "10px 0 3px",
  },
  MeetDrPalinFooterTitle: {
    color: theme.palette.primary.main,
    fontFamily: "GothicRegular",
    textTransform: "uppercase",
    alignSelf: "center",
    //fontSize: "1.25rem",
    letterSpacing: " 6px",
    width: "100%",
    display: "flex",
    paddingBottom: 12,
    paddingTop: 15,
    justifyContent: "center",
  },
  meetDrPalin: {
    background: "white",
  },
  MemberShipImgDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    marginBottom: "25px",
  },
  footerBookBtnDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  footerBookBtnDivMeetDrPalin: {
    width: "100%",
    boxShadow: "0px 6px 40px 0px #d9d9d9",
  },
  menImgDiv: {
    height: "60%",
    width: "15%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      height: "100%",
      margin: "10px 40px",
    },
  },
  menImg: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
  },
  menBMAImg: {
    width: "60%",
  },
  memText: {
    margin: 0,
    marginTop: "2em",
  },
}));

const Footer = ({
  isInVh = false,
  isNodisplay = false,
  history,
  isPartnerNodisplay = false,
  OpenPopUpModalFunc = (_) => undefined,
  isSignFooter = false,
  isMeetDrPalin = false,
  descriptionDifferent = false,
  width,
  bookConsultationButton = true,
}) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <div
      className={clsx(classes.footerContainer, {
        [classes.footerContainerWhite]: isMeetDrPalin,
      })}
    >
      <Partners isNodisplay={isPartnerNodisplay} />
      <div
        className={clsx(
          location.pathname === "/"
            ? classes.footerBookBtn
            : classes.footerBookOtherPageBtn,
          {
            [classes.displayNone]: isNodisplay,
            [classes.meetDrPalin]: isMeetDrPalin,
          }
        )}
      >
        <div
          className={clsx(classes.footerBookBtnDiv, {
            [classes.footerBookBtnDivMeetDrPalin]: isMeetDrPalin,
          })}
        >
          <div
            className={clsx(classes.bookConsultationPara, {
              [classes.displayNone]: isSignFooter || isMeetDrPalin,
              [classes.isNotSignFooter]: !isSignFooter,
            })}
          >
            {location.pathname === "/" && (
              <Typography className={classes.lineOne}>
                Book a consultation now with one of the UK's leading cosmetic
                doctor
              </Typography>
            )}
            {location.pathname === "/weight-loss" && (
              <Typography className={classes.lineOne}>
                Book a consultation now with one of the UK's leading cosmetic
                doctor
              </Typography>
            )}
          </div>
          <div
            className={clsx({
              [classes.displayNone]: isMeetDrPalin,
            })}
          >
            {location.pathname === "/" && (
              <Typography
                className={clsx(classes.togetherPara, {
                  [classes.isNotSignFooter]: !isSignFooter,
                })}
              >
                Together, let's take a step closer to a better you
              </Typography>
            )}
            {location.pathname !== "/" && (
              <Typography className={clsx(classes.togetherPara, !isSignFooter)}>
                Together, let's take a step closer to a better you
              </Typography>
            )}
          </div>
          {location.pathname === "/" && (
            <div
              className={clsx({
                [classes.displayNone]:
                  (isSignFooter && width > 860) ||
                  !bookConsultationButton ||
                  isMeetDrPalin,
                [classes.isNotSignFooterBookButton]: !isSignFooter,
              })}
            >
              <Button
                // onClick={() => history.push("/book-consultation")}
                onClick={() => window.location.href = 'https://partner.pabau.com/online-bookings/DPA-Clinics'} 
                className={classes.bookBtn}
                variant="contained"
                disableElevation
              >
                {/* BOOK A consultation */}
                BOOK AN APPOINTMENT
              </Button>
            </div>
          )}
          {location.pathname === "/weight-loss" && (
            <div
              className={clsx({
                [classes.displayNone]:
                  (isSignFooter && width > 860) ||
                  !bookConsultationButton ||
                  isMeetDrPalin,
                [classes.isNotSignFooterBookButton]: !isSignFooter,
              })}
            >
              <Button
                // onClick={() => history.push("/book-consultation")}
                onClick={() => window.location.href = 'https://partner.pabau.com/online-bookings/DPA-Clinics'} 
                className={classes.bookBtn}
                variant="contained"
                disableElevation
              >
                BOOK A consultation
              </Button>
            </div>
          )}
          <div className={clsx(classes.signGif, {})}>
            <ReLoadGif />
          </div>
          {/* <div className={clsx(classes.MeetDrPalinFooterTitle,{
                        [classes.displayNone]:(!isMeetDrPalin),
                    })}>
                            <MyTypograph variant="Title" fontSize="1.25rem" >
                                <p className={clsx(classes.memText)} >MemberShips</p>
                            </MyTypograph>
                    </div> */}
          {/* <div className={clsx(classes.MemberShipImgDiv, {
                        [classes.displayNone]:(!isMeetDrPalin),
                    })}> 
                        <div className={classes.menImgDiv}>
                            <img className={classes.menImg} src={Member1} alt=""/>
                        </div>
                        <div className={clsx(classes.menImgDiv)}>
                            <img className={clsx(classes.menImg, classes.menBMAImg)} src={Member2} alt=""/></div>
                        <div className={classes.menImgDiv}>
                            <img className={classes.menImg} src={Member3} alt=""/></div>
                    </div> */}
        </div>
      </div>
      <div
        className={clsx(classes.footer, {
          [classes.footerInVh]: isInVh,
        })}
      >
        <SocialMediaIconCommon forFooter={true} />

        <div
          className={clsx({
            [classes.footerCopyRight]: !descriptionDifferent || width > 860,
            [classes.mobFooterCopyRight]: descriptionDifferent,
          })}
        >
          {"©2020 DR PALIN AESTHETICS"}
          {/* <span>
            {descriptionDifferent
              ? "| LOCATIONS IN ARCHERS OAK, 2A SADDLEBACK DRIVE, MANCHESTER, CHESHIRE, SK10 4AR"
              : "| LOCATIONS IN GREATER MANCHESTER, COVENTRY & LONDON"}
          </span> */}

          <span>
            {/* {descriptionDifferent
              ? "| LOCATIONS IN MANCHESTER (CHESHIRE) , 2A SADDLEBACK DRIVE, MANCHESTER, CHESHIRE, SK10 4AR"
              : "| LOCATIONS IN MANCHESTER (CHESHIRE) , COVENTRY & LONDON"} */}
            {"| LOCATIONS IN MANCHESTER (CHESHIRE), COVENTRY & LONDON"}
          </span>
        </div>
        <div className={classes.footerNavBar}>
          <ul>
            <li>
              <span onClick={() => history.push("/contact-us")}>
                CONTACT US
              </span>
              <span className={classes.footMenu}> | </span>
            </li>
            <li
              onClick={() => {
                OpenPopUpModalFunc("Privacy");
              }}
            >
              <span>PRIVACY POLICY</span>
              <span className={classes.footMenu}> | </span>
            </li>
            <li
              onClick={() => {
                OpenPopUpModalFunc("TandC");
              }}
            >
              <span>TERMS &amp; CONDITIONS</span>
            </li>
          </ul>
        </div>
        {/* <div className={classes.developedByCls}>
          <a href="https://www.iihglobal.com/" target="blank">
            Website Developed by IIH Global
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default withRouter(Footer);
