import React,{ useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Insta from '../../assets/image/insta.svg'
import InstaDark from '../../assets/image/instadark.svg';
import Twitter from '../../assets/image/twiiter.svg'
import TwitterDark from '../../assets/image/twiiterdark.svg'
import Facebook from '../../assets/image/facebook.svg'
import FacebookDark from '../../assets/image/facebookdark.svg'
import YouTube from '../../assets/image/youtube.svg'
import YouTubeDark from '../../assets/image/youtubedark.svg'

// https://twitter.com/drmartinpalin
// https://www.instagram.com/drmartinpalin/
// https://www.facebook.com/drmartinpalin
// https://www.snapchat.com/add/drmartinpalin

const useStyle = makeStyles(theme=>({ 
    socialIcon:{
        display:"flex",
        '& *':{
            fontSize: "30px",
            cursor:"pointer",
            borderRadius: "75%",
            height: "35px",
            transition: "transform .2s",
        },
        '& svg': {
            margin: "0px 5px",
         },
         
    },
    background:{
        margin:"10px",
    },
}));

const SocialMediaIcon = () =>{
    const classes = useStyle();

    const [darkIcon, setDarkIcon] = useState({
        insta : {
            dark : false
        },
        twitter : {
            dark : false
        },
        facebook : {
            dark : false
        },
        youtube : {
            dark : false
        }
    });

    function turnIconDark(key){
        setDarkIcon((darkIcon)=>({
            ...darkIcon,
            [key] : {
                dark : !(darkIcon[key]['dark'])
            }
        }))
    }
    function socialRedirect (data){

        window.open(data);
    }

    return(
        <div className={classes.socialIcon}>
            <div className={classes.background}>
                <img
                 src={darkIcon.insta.dark ? InstaDark : Insta}
                 alt='instagram'
                 onClick={()=>socialRedirect('https://www.instagram.com/drmartinpalin/')}
                 onMouseEnter={()=> turnIconDark('insta')}
                 onMouseLeave={()=> turnIconDark('insta')}
                /> 
            </div>
            {/* <div className={classes.background}>
                <img
                 src={darkIcon.twitter.dark ? TwitterDark : Twitter }
                 alt='twitter'
                 onClick={()=>socialRedirect('https://twitter.com/drmartinpalin')}
                 onMouseEnter={()=> turnIconDark('twitter')}
                 onMouseLeave={()=> turnIconDark('twitter')}
                /> 
            </div> */}
            <div className={classes.background}>
                <img
                 src={darkIcon.facebook.dark ? FacebookDark : Facebook}
                 alt='facebook'
                 onClick={()=>socialRedirect('https://www.facebook.com/drmartinpalin')}
                 onMouseEnter={()=> turnIconDark('facebook')}
                 onMouseLeave={()=> turnIconDark('facebook')}
                /> 
            </div>
            <div className={classes.background}>
                <img
                  src={darkIcon.youtube.dark ? YouTubeDark : YouTube}
                  alt='youtube'
                  onClick={()=>socialRedirect('https://www.youtube.com/c/DrPalinAesthetics')}
                  onMouseEnter={()=> turnIconDark('youtube')}
                  onMouseLeave={()=> turnIconDark('youtube')}
                /> 
            </div>
        </div>
    );
}
export default SocialMediaIcon